import {
  createStylesParam as untypedCreateStylesParam,
  StyleParamType,
} from '@wix/tpa-settings';
import { CustomCssVarsFn } from '@wix/yoshi-flow-editor';
import { useStyles } from '@wix/tpa-settings/react';

import {
  RssWidgetIconType,
  type RssWidgetStyleParams,
} from '@wix/communities-blog-client-common';

const styleParams = {
  colors: {
    'widget-iconColor': makeColor('widget-iconColor'),
    'widget-iconBackgroundColor': makeColor('widget-iconBackgroundColor'),
    'widget-iconColorNoBackground': makeColor('widget-iconColorNoBackground'),
  },
  numbers: {
    'widget-iconBackgroundType': makeNumber(
      'widget-iconBackgroundType',
      RssWidgetIconType.Square,
    ),
  },
} satisfies {
  colors: Record<keyof RssWidgetStyleParams['colors'], any>;
  numbers: Record<keyof RssWidgetStyleParams['numbers'], any>;
};

function makeColor(key: keyof RssWidgetStyleParams['colors']) {
  return untypedCreateStylesParam(key, {
    type: StyleParamType.Color,
  });
}

function makeNumber<K extends keyof RssWidgetStyleParams['numbers']>(
  key: K,
  fallbackValue: RssWidgetStyleParams['numbers'][K],
) {
  return untypedCreateStylesParam(key, {
    type: StyleParamType.Number,
    getDefaultValue: () => fallbackValue,
  });
}

export function useRssWidgetStyleParams(): RssWidgetStyleParams {
  const styles = useStyles();

  const { colors } = styleParams;
  const enums = getEnumsFromNumbers();

  return {
    colors: {
      'widget-iconColor': styles.get(colors['widget-iconColor']),
      'widget-iconBackgroundColor': styles.get(
        colors['widget-iconBackgroundColor'],
      ),
      'widget-iconColorNoBackground': styles.get(
        colors['widget-iconColorNoBackground'],
      ),
    },
    numbers: {
      'widget-iconBackgroundType': enums.backgroundType,
    },
  };

  function getEnumsFromNumbers() {
    const backgroundType: RssWidgetIconType =
      styles.get(styleParams.numbers['widget-iconBackgroundType']) ??
      RssWidgetIconType.Square;

    return {
      backgroundType,
    };
  }
}

export const customCssVars: CustomCssVarsFn = (tpaParams) => {
  const cssVars = {
    backgroundType: makeDisplayVarsFromEnumParam(
      'widget-iconBackgroundType',
      new Map<RssWidgetIconType, string>([
        [RssWidgetIconType.Square, 'square'],
        [RssWidgetIconType.Circle, 'circle'],
        [RssWidgetIconType.None, 'none'],
      ]),
    ),
  };

  return {
    ...cssVars.backgroundType,
  };

  function makeDisplayVarsFromEnumParam<
    T extends keyof RssWidgetStyleParams['numbers'],
  >(
    numericParamKey: T,
    enumToSuffixMap: Map<RssWidgetStyleParams['numbers'][T], string>,
  ) {
    const entries = [...enumToSuffixMap.entries()].map((entry) => {
      const enumValue = entry[0];
      const suffix = entry[1] as string;

      return makeDisplayVar(numericParamKey, suffix, enumValue, 'block');
    });

    return Object.fromEntries(entries) as Record<
      (typeof entries)[0][0],
      (typeof entries)[0][1]
    >;
  }

  type CssDisplayValue = 'flex' | 'block' | 'inline';

  function makeDisplayVar<
    T extends keyof RssWidgetStyleParams['numbers'],
    S extends string,
  >(
    numericParamKey: T,
    suffix: S,
    equalsToValue: RssWidgetStyleParams['numbers'][T],
    cssValue: CssDisplayValue,
  ) {
    const varName = `${numericParamKey}_display--${suffix}` as const;
    const displayValue =
      tpaParams.styleParams.numbers[numericParamKey] === equalsToValue
        ? cssValue
        : 'none';

    return [varName, displayValue] as const;
  }
};

export default {
  ...styleParams.colors,
  ...styleParams.numbers,
};
